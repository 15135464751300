/*eslint-disable*/
import React from "react";
import logo from "../../assets/img/tubers_logo.svg"
// reactstrap components
import { Container } from "reactstrap";
import eu from '../../assets/img/logos/eu.jpg'


function DarkFooter() {
  return (
    <footer className="footer">
    
    <div class="container py-5">

      <div class="row py-4">
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
       
        
      
        <h6 class="text-uppercase font-weight-bold mb-4">TUBERS Facts</h6>
        <hr/>
        <ul id='ulu' style={{color:'#dadada'}}>
            <li  style={{display:'block'}}>Starting date: 1st September 2022</li>
            <li  style={{display:'block'}}>Duration: 48 months </li>
            <li  style={{display:'block'}}>Coordinator: TWI Hellas</li>
            <li  style={{display:'block'}}>Consortium: 8 partners from 4 EU countries </li>
            <li  style={{display:'block'}}>More TUBERS info at <a id='cord' target="_blank"  href='https://cordis.europa.eu/project/id/101070115'>CORDIS</a> </li>
          </ul>

        </div>
        
        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0" id='marginfooter'>
          <h6 class="text-uppercase font-weight-bold mb-4" style={{fontSize:'30px'}}>Follow us</h6>
          <hr style={{width:'140px'}}/>
          <ul class="list-unstyled mb-0">
            <li class="mb-2"> <a target="_blank"  href='https://www.linkedin.com/showcase/tubers-project/'> <i id='social' className="fab fa-linkedin"></i></a></li>
            <li class="mb-2"> <a target="_blank"  href='https://www.youtube.com/channel/UCPd5JG9Urnbp3huqCUqIIVg'> <i id='social' className="fab fa-youtube"></i></a></li>
            <li class="mb-2"> <a target="_blank"  href='https://twitter.com/tubers_project'> <i id='social'className="fab fa-twitter"></i></a></li>
          </ul>
          
        </div>
        <div class="col-lg-4 col-md-6 mb-lg-0" id='marginfooter'>
          <img src={eu} style={{width:'40px'}}></img>
          <hr/>
          <p style={{color:'#dadada', fontSize:'1em'}}>This project has received funding from the European Union’s Horizon Europe programme under Grant Agreement N°101070115.</p>
     
          <hr/>
          
        </div>
      </div>
    </div>
    <div class="bg-light py-4">
      <div class="container text-center">
        <p class=" mb-0 py-2" style={{color:'#07353d'}}>© 2024 Tubers. All rights reserved.</p>
      </div>
    </div>

    </footer>
  );
}

export default DarkFooter;


/*<a id='a'href="https://www.w3schools.com" style={{color:'#dadada',fontSize:'14px',cursor:'pointer'}}>Privacy policy</a>*/
import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg8 from '../assets/img/TUBERS5.png'
import pdffile from './pdffile.pdf'
import icon from '../assets/img/pdficon.svg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article8 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  TUBERS research gets published for the first time            
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 14/05/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg8} title=""  width="520" height="291" />
               <p style={{textAlign:'justify'}}>Our TUBERS consortium is excited to announce its first project publication! The paper,
"Enhancing Material Thickness Measurement: Ultrasonic Sensor Data Analysis and Thickness
Prediction Using Neural Networks,” was published in the “Applications of Modelling and
Simulation" journal in April 2024. Congratulations to the team from the AI Innovation Centre of
the <a id='hyperlink' href='https://www.essex.ac.uk/'>University of Essex</a>, specifically to the authors Vahid Hassani, Antonis Porichis, Farhan
Mahmood, and Panagiotis Chatzakos.
</p>
<p style={{textAlign:'justify'}}>
In this study, neural networks were used to enhance the accuracy of thickness measurements
for immersed steel samples. Training data was gathered by conducting experiments on
immersed wedge samples with varying thicknesses using the A-scan method. This data was
then used to train a single-layer neural network. The performance of the trained neural network
was evaluated using test data from different samples with various thicknesses. The study
demonstrated a promising methodology for accurate thickness prediction using neural networks,
with outcomes showing good agreement when predicting void locations in similar materials. The
method achieved an error of less than 3% for thickness prediction and less than 7% for void
detection.</p>
<p style={{textAlign:'justify'}}>The publication of our research findings marks a significant milestone for the TUBERS
consortium. Our team is dedicated to ongoing collaboration and knowledge sharing within the
scientific and technological community, and we are eager to see the potential impact of our
research in the field of ultrasonic sensors and material analysis.</p>
<p style={{textAlign:'justify'}}>Access the full publication <a id='hyperlink' href='https://repository.essex.ac.uk/38220/1/615-1959-1-PB.pdf'>here</a>.</p>


              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article8
import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg4 from '../assets/img/article4.png'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article2 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  Demcon attends the Vision, Robotics and Motion event                  
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 13/06/2022
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg4} title=""  width="520" height="291" />
                <p style={{textAlign:'justify'}}>
                Between the 7th and 8th of June 2023, <a id='hyperlink' href="https://demcon.com/">Demcon</a> participated as an exhibitor in the <a id='hyperlink' href="https://vision-robotics.nl/previous-edition/">Vision,
Robotics & Motion</a> trade fair and convention in ‘s-Hertogenbosch, the Netherlands. Every year,
the event brings together companies, experts and interested parties to share knowledge and
strengthen networks.</p>
<p style={{textAlign:'justify'}}>
Demcon had a small stand where a prototype version of the snake-like robot was displayed to
showcase Demcon’s vision and its experience with robotic vision, machine learning and
synthetic data, along with showcasing the application of the company’s technologies on projects
like TUBERS.
</p>
<p style={{textAlign:'justify'}}>
Check out the event’s <a id='hyperlink' href="https://www.youtube.com/watch?v=nVOFNF_DrX0">after-movie</a> to get a better idea of the event.
</p>

              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article2
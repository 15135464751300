import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/newstyle.scss'
import blogimg1 from '../assets/img/shutterstock_1984609232.jpg'



import SingleBlog from './Article1'

const Blog2 = () => {




  return (
    <div id="layout" className="pure-g">
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts" >

			  
              <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg1}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">Challenges and Innovations in the Water Industry</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 13/06/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">TFrom March to May 2024, our TUBERS consortium attended different conferences in the water
industry, from WWT Innovation and Smart Water Conference to Journées économiques France-
Maroc: l'eau, enjeux et perspectives and the Utility Week Congress.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Challenges and Innovations in the Water Industry'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
         
          </div>


          
        </div>
      </div>
    </div>
  )
}

export default Blog2
import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg6 from '../assets/img/blogimg3.jpg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article6 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  Project TUBERS & RIMA Alliance:
Main Challenges in Inspection & Maintenance Robotics                 
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 29/01/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg6} title=""  width="520" height="291" />
               <p style={{textAlign:'justify'}}>Since its initiation, our TUBERS project has sought to develop a system of robotic technologies
that will inspect, monitor, and repair micro-leaks in drinking water pipe distribution networks.
Therefore, establishing a stronger connection with the RIMA (Robotics for Inspection and
Maintenance) Alliance has been a priority due to its extensive experience in robotics in
numerous fields like civil engineering, infrastructure, water supply and energy, amongst others.</p>
<p style={{textAlign:'justify'}}>
To further develop our connection with RIMA Alliance, its Digital Innovation Hubs and industrial
organisations, we have conducted a very interesting conversation on the challenges in
inspection and maintenance Robotics with Ladislav Vargovčík, the Director of Prototyping and
Innovation Centre at TUKE and Aksel A. Transeth, a senior scientist at SINTEF Digital.
</p>
<p style={{textAlign:'justify'}}>
During the discussion, the RIMA Open Calls have been discussed from the document focusing
on the Inspection and Maintenance challenges, the interviews with companies in the chemical
and gas sector, Digital Innovation Hub members, as well as the different working groups
focusing on nuclear energy, transportation and other industries. This way, the best use cases
are to be identified to support the development and deployment of robotics I&amp;M applications
with grants.</p>
<p style={{textAlign:'justify'}}>Then, we asked our esteemed guests how they have been approaching specific situations in the
field of Inspection and Maintenance.</p>
<p style={{textAlign:'justify'}}><b style={{fontStyle:'italic'}}>How do you identify and select interesting inspection and maintenance use cases in
industries as diverse as Oil and Gas, Power and utilities, and Public Infrastructure?</b></p>
<p style={{textAlign:'justify'}}><b>Ladislav Vargovčík:</b> The solution to employ a robot that moves within the tubes is practical and
has many advantages, like protecting the environment against spreading radiation and external
contamination, especially in the case of nuclear systems since the robot is inside the tube.</p>
<p style={{textAlign:'justify'}}><b>Aksel A. Transeth:</b> There has always been much interest, even looking ten years into the past,
in finding suitable use cases or coming up with interesting ones in the supply industry and from
the side of the asset owners. Throughout the years, there has been difficulty in identifying use
cases that are possible to materialise as well as cost-effective to be purchased, adopted and
implemented in the fitting industries.</p>
<p style={{textAlign:'justify'}}><b style={{fontStyle:'italic'}}>How do you engage with the asset owners and their contractors to ensure that you fully
understand the problem that needs to be solved instead of demonstrating how cool your
robot looks? For visual inspection topics, if you don’t investigate the details in depth,
you won’t realise that some or most of the surfaces/areas of interest require cleaning
before visual inspection. Therefore, your robotic solution also needs to be able to
perform cleaning operations effectively with the appropriate tools.</b></p>

<p style={{textAlign:'justify'}}><b>Aksel A. Transeth:</b> The first critical step is to gather information through pictures, videos and
interviews with the field experts since they are the ones who fully understand the problem at
hand. For research organisations, the trick is to avoid getting trapped in the research part of the
problem when discussing with the asset owners. They do not come in direct contact with the
situation, so they do not know all the intricacies of the problem to be resolved. Additionally,
posing questions about the surrounding environment and activities is quite essential to
comprehend the different services needed. For example, in order to inspect a tank within a tank,
the issues of cleaning and repairing and the factor of human involvement may also come into
the picture.</p>
<p style={{textAlign:'justify'}}><b>Ladislav Vargovčík:</b> There should be a convergence of opinions and ideas as well as the
availability of the necessary input data in order to come to an agreement between the asset
owners, field experts and us for the project to move forth.</p>

<p style={{textAlign:'justify'}}><b style={{fontStyle:'italic'}}>How do you collect the input data and define the starting assumptions regarding the
operating conditions your robotic process will face? For example, in many NDT
corrosion inspection processes, relevant structured data are absent, which means that
for your AI support system to spot defects, extra effort in training will be required.</b></p>

<p style={{textAlign:'justify'}}><b>Aksel A. Transeth:</b> We try to get pieces of the infrastructure or actual equipment. So, for
example, if you are working with a tank, we ask to have a part of a tank in our lab. Additionally,
we have built a test plate with different sizes of damage in order to ask the client if what we
have been working on has been representative of the respective conditions to understand the
effectiveness of the sensors.</p>
<p style={{textAlign:'justify'}}><b>Ladislav Vargovčík:</b> Regarding reproducing a nuclear area, obtaining knowledge about the
problem as fast as possible and being on the same page with the asset owners is vital. At the
same time, tests should be conducted in order to know if certain technologies are working.</p>

<p style={{textAlign:'justify'}}><b style={{fontStyle:'italic'}}>How open to changes are asset owners to facilitate the performance of a robotic
application? For example, there are different types of materials in the water pipe
distribution systems in the water management industry. Not all of them are suitable for
maintenance or repair by robotic systems due to their age and/or fragility.</b></p>

<p style={{textAlign:'justify'}}><b>Aksel A. Transeth:</b> These kinds of issues may come up, and we call them “surprises”.
Typically, we would experiment with equipment and materials we bought to create a mock-up
system. However, in actual conditions, that equipment and materials may even be 30 years old,
so they cannot be dismantled or are fragmented due to their rustiness. Therefore, the robot may
not be able to work with them, and adjustments to the equipment should be made.</p>
<p style={{textAlign:'justify'}}><b>Ladislav Vargovčík:</b> Some asset owners are concerned about adding new infrastructure (parts,
materials, equipment) since they may require additional maintenance. Some of them are costly 
(adding cables or radio beacons) or require further human involvement in the case of battery
change in wireless connections.</p>
<p style={{textAlign:'justify'}}>Overall, the first of the series of interviews was very fruitful and provided insights into the
processes, requirements and difficulties during the R&amp;D phase. Many thanks to our first two
guests, and we hope to dive even deeper into the topics that interest our consortium as well as
the RIMA Alliance.</p>

              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article6
import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import blogimg1 from '../assets/img/shutterstock_1984609232.jpg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";

const Blog1 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  Challenges and Innovations in the Water Industry
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 13/06/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg1} title=""  width="520" height="291" />
                <p style={{textAlign:'justify'}}>
                From March to May 2024, our TUBERS consortium attended different conferences in the water
industry, from <a id='hyperlink' href='https://event.utilityweek.co.uk/innovation-smart24'>WWT Innovation and Smart Water Conference</a> to 
<a id='hyperlink' href='https://cfcim.app.swapcard.com/event/journees-economiques-maroc-france'> Journées économiques France-Maroc: l'eau, enjeux et perspectives</a> and the 
<a id='hyperlink' href='https://www.utilityweeklive.co.uk/content-programme-2024'> Utility Week Congress</a>. There, our Joel van den
Bosch from our partner <a id='hyperlink'href="https://www.alsymex-alcen.com/">Alsymex, </a>attending the above events, seized the opportunity to meet
with water company representatives and municipalities to discuss the topics of Non-Revenue
Water (NRW) and leakage issues in the water distribution systems.
</p>
<p style={{textAlign:'justify'}}>Therefore, to inform the members of our TUBERS community, we have collected the key
takeaways and valuable information received through the discussions about the current state of
the water industry.</p>

<p style={{textAlign:'justify'}}>Over the last 7+ years, investment in digitalising pipe networks with smart metering, pressure
sensors, and data processing in hydraulic predictive models (increasingly AI-driven) has
contributed to effectively preventing leakage crises and impacted very positively the speed of
technological intervention to eliminate the leaks. However, accurately locating leakage and
replacing the leaking pipe sections remain labour-intensive field activities.</p>

<p style={{textAlign:'justify'}}>Water companies and municipalities operate distribution networks in arid climate regions. Under
the harsh conditions mentioned above, they increasingly face the challenge of maintaining the
distribution pipes connecting multiple reservoirs and hydraulic basins in pristine condition. By
doing so, they ensure the supply of vital drinking water in sufficient volume to the population all
year round.</p>

<p style={{textAlign:'justify'}}>Yet, as the population in urban areas grows, so does the need for improved water network
management since the distribution networks become increasingly complicated. The acceleration
of cycles in changing weather climate patterns shift repeatedly and quickly from dry-high to rainy
to sub-zero temperatures, and the intervention teams are under additional stress as these
conditions heavily challenge the integrity of the networks.</p>

<p style={{textAlign:'justify'}}>For all the reasons mentioned above, over the past years, the <a id='hyperlink' href='https://ukwir.org/leading-the-water-industry-research-agenda'>UK Water Industry Research</a>
(UKWIR) initiated a <a id='hyperlink' href='https://ukwir.org/How-will-we-achieve-zero-leakage-in-a-sustainable-way-by-2050'>Leakage Innovation Heatmap</a> under the leadership of Jeremy Heath from
<a id='hyperlink' href='https://seswater.co.uk/'>SES Water</a>. The goal is to provide fertile ground for technological projects to meet the demand
for improved water distribution and to answer one of the UKWIR's most critical questions: "How
will we achieve Zero Leakage in a sustainable way by 2050?"</p>

                 
              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Blog1
import React from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Mapchart from '../map.js'
import signature from "../signaturejs.js";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ContactForm from './contact'
import DarkFooter from "../../components/Footers/DarkFooter.js";
import cons from '../cons.png'
import test from '../../assets/img/test2.svg'
import { HashLink } from "react-router-hash-link";
import CustomForm from '../../components/SubscribeForm'
import blogimg6 from '../../assets/img/smart.jpg'
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import MailchimpSubscribe from "react-mailchimp-subscribe"


const url = "https://gmail.us14.list-manage.com/subscribe/post?u=047540e6f745f9512f7cbb36e&amp;id=a0467af439&amp;f_id=00faf4e0f0";

const SimpleForm = () => <MailchimpSubscribe url={url}/>

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
        <LandingPageHeader i />
        <section className="section section-about-us" id='mission'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#206a72'}}>Our Mission Statement</h2>
                <h5 className="description" style={{textAlign:'justify',color:'#07353d'}}>
                TUBERS intends to become a resilient protective shield against the many evils that plague the current water distribution system in Europe, such as increased energy consumption,
                 leakages and water contamination. The plan is to prevent or resolve leakages from ageing pipes or pipeline construction issues through constant high-accuracy water pipe inspections and repairs.
                  Thus, our project will build an ecosystem of robotic tools, digital systems, eco-friendly solutions and innovative techniques for water resource optimisation. Overall, the TUBERS consortium will offer a scalable and innovative approach to the water value chain to save leaking water,
                 spent energy and lost revenue, hence advocating for a circular economy.
               
                </h5>
              </Col>
            </Row>
            </Container>
            </section>
            <section className="section section-about-us" id='consortium' style={{backgroundColor:'#07353D'}}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
                <h2 className="title" style={{color:'white'}}>Our Consortium</h2>
                <h5 className="description" style={{textAlign:'justify',color:'white'}}>
                The development of TUBERS is undertaken by a multinational consortium of two technology providers from Greece and the UK (THL, UESS), three industrial partners from the Netherlands,
                 France and Greece (DMC, ALS and BBL) and three end-user companies from the Netherlands (BRW, VIT and EVW). The multidisciplinary members bring complementary skills and expertise to the table,
                  from machine learning, ultrasonic testing and teleoperation for inspection/maintenance to robotics and pneumatically actuated soft robotics. In turn, the three end-user companies contribute 
                  the most valuable insights into the water network management, 
                focusing on its life cycle aspects and functional requirements as well as providing access to their water networks for testing field trial purposes later in the project.
               
                </h5>
                <br/>
                <img src={cons}></img>
              </Col>
              
            </Row>
            </Container>
            </section>
            <section id='latestnews'>
            <div className="section section-team text-center" style={{backgroundColor:'#6eb1b8'}}>
            <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
              <h2 className="title" style={{color:'white'}}>Latest News</h2>
              <div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg6}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">Project TUBERS attends Smart Water Utilities 2023</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						05/07/2023
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">The Smart Water Utilities conference took place in Amsterdam between 28 - 29 June 2023,
uniting experts, researchers,</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Project TUBERS attends Smart Water Utilities 2023'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    <HashLink to='/news#news'>
    <button class="subscribe-btn">Read more</button>
    </HashLink>
    </div>
           
              </Col>
              
            </Row>
            </Container>
            </div>
            </section>

        <section id='mailchimp'>
        <div className="section section-team text-center" style={{backgroundColor:'#1e646c'}}>
        <Container>
            <Row>

              <Col className="ml-auto mr-auto text-center" md="8">
           <div id='rect'>
              <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (

      <div  id='simple' style={{padding:'40px'}}>
        <h5>Subscribe to our newsletter and stay updated.</h5>
        <SimpleForm  onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div style={{ color: "#1e646c",position:'absolute' }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" ,position:'absolute'}} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "#1e646c" }}>Subscribed !</div>}
      </div>
    )}
  />
  </div>
              </Col>
            </Row>
            </Container>
        </div>
        </section>
       
        <ContactForm/>
        <DarkFooter />
        <CookieConsent
  location="bottom"
  buttonText="Agree"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#f4f4f4",padding:'10px',position:'fixed', overflowY:'auto',borderTop:'2px solid #1c9caa',display:'block'}}
  buttonStyle={{ color: "#dadada", fontSize: "13px",borderRadius:'5px',background:'#1c9caa'}}
  expires={150}
>
  <h6 style={{color:'#07353d',textTransform:'none'}}>In order to provide you with the best online experience this website uses cookies.</h6>
     <h6 style={{color:'#07353d',textTransform:'none'}}>By using our website, you agree to our use of cookies.</h6>
    
</CookieConsent>
      </div>
    </>
  );
}

export default LandingPage;

import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg4 from '../assets/img/ICDevent.png'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article2 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  The TUBERS tech joins the ICD ''Robo'' event with Demcon                  
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 08/06/2022
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg4} title=""  width="520" height="291" />
                <p style={{textAlign:'justify'}}>
                An interesting event for our TUBERS project occurred in the first week of June. On June 3rd
2023, Demcon participated in the
<a id='hyperlink'href="https://www.icdrachten.nl/en/news/a-day-full-of-robotics-adventure/"> ICD “Robo” event </a> as an exhibitor.
</p>
<p style={{textAlign:'justify'}}>
In the ICD “Robo” event, our partner had a stand in the central area of the largest room in the
venue and showcased its robotics projects, one of which was a prototype version of our Tubers
snake-like robot. The snake-like robot's innovative appearance and functionalities captured the
attendees’ imagination.
</p>
<p style={{textAlign:'justify'}}>Four categories of invitees attended:</p>
<ul>
  <li>employees of Philips Drachten (about 2000)</li>
  <li>family members of Philips employees</li>
  <li>employees of companies in the <a id='hyperlink' href="https://www.icdrachten.nl/bedrijven/">Innovation Cluster Drachten</a></li>
  <li>and other invitees from the region</li>
</ul>

  <p style={{textAlign:'justify'}}>Check out the <a id='hyperlink' href='https://www.youtube.com/watch?v=9nNIhHUXoQw'>YouTube video</a> of the event for more information.</p>



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article2
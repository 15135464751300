import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";


import blogimg1 from '../assets/img/Journées.jpg'
import pdffile from './pdffile.pdf'
import icon from '../assets/img/pdficon.svg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article10 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  TUBERS at the France-Morocco Economic Days         
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 13/03/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg1} title=""  width="420" height="291" />
               <p style={{textAlign:'justify'}}>Our project, through our partner <a id='hyperlink' href='https://www.alsymex-alcen.com/'>Alsymex</a>, was invited to participate in the Summit Day during
the “Journées économiques France—Maroc/L'eau: enjeux et perspectives” event. It was
organised by the <a id='hyperlink' href='https://www.cfcim.org/'>French Chamber of Commerce and Industry in Morocco (CFCIM)</a> in
partnership with the <a id='hyperlink' href='https://fr.diplomatie.ma/'>Embassy of the Kingdom of Morocco</a> in France. The whole venture focused
on water-related issues and challenges between France and Morocco to achieve better water
management in the future.
</p>
<p style={{textAlign:'justify'}}>
The event, which took place in Lyon on Tuesday, March 5th, gathered representatives from the
Moroccan Ministry of Water, other industry players, and technology experts. The main topic of
the day was to dive deeper into the current state of water distribution and network efficiency and
touch upon cases relevant to agriculture, collective resource management, and financing as a
catalyst for innovation.</p>
<p style={{textAlign:'justify'}}>
Therefore, the TUBERS project was at the right place at the right time since there were many
discussions and exchanges on the topic of water preservation and sustainability with
professionals from the Fédération Nationale des Canalisateurs, group BRUNET, CMR
Compagnie Nationale du Rhône, the Moroccan Ministry of Equipment and Water, and many
other esteemed companies and organisations.
</p>


              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article10
import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import blogimg1 from '../assets/img/blogimg1.jpg'
import blogimg2 from '../assets/img/blogimg2.jpg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";

const Article1 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  The ultimate insight look at the TUBERS kick-off meeting
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 13/10/2022
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg1} title=""  width="520" height="291" />
                <p style={{textAlign:'justify'}}>
                  The beginning of an engaging journey for the TUBERS project consortium commenced and was
sealed with a kick-off meeting! On October 5th 2022, representatives from all partners
(<a id='hyperlink'href="https://demcon.com/">DEMCON, </a>
<a id='hyperlink'href="https://www.twi-hellas.com/">TWI Hellas, </a>
<a id='hyperlink'href="https://www.alsymex-alcen.com/">Alsymex, </a>
<a id='hyperlink'href="https://bendabl.com/">Bendabl, </a>
<a id='hyperlink'href="https://www.essex.ac.uk/">University of Essex, </a>
<a id='hyperlink'href="https://www.brabantwater.nl/">Brabant Water, </a>
<a id='hyperlink'href="https://www.evides.nl/">Evides, </a> 
<a id='hyperlink'href="https://www.vitens.nl/">Vitens </a>) came together to lay the groundwork and find out how to prevent the water leaks that
trouble the European water distribution system at the end of the four-year Horizon Europe
project.</p>
<p style={{textAlign:'justify'}}>
On that day, partner organisations presented their previous and current research and business
endeavours while highlighting their technological strengths that will lead to the project’s
completion. As expected, there was also an extensive discussion about future research
developments, essential tasks and milestones. Overall, the inaugural meeting was ideal for the
consortium members to exchange opinions on technical and non-technical matters and set a
common course of action to achieve optimal results.
</p><img id='blogimg' style={{float:'left',margin:' 0  20px 0px 0px'}} src={blogimg2} width="250"  />
<p style={{textAlign:'justify'}}>
On day two, the TUBERS partners had the
opportunity to visit <a id='hyperlink'href="https://www.utwente.nl/en/">the University of Twente </a> 
(UT) and <a id='hyperlink' href="https://www.kwrwater.nl/en/">KWR Water Research Institute </a>. The
tour included a walk through the university&#39;s
different departments, such as robotics,
drones and 3D printers. Additionally, part of it
was also a demonstration of developed
technologies and results of previous Demcon
and UT projects. Later, at KWR, the partners
visited Nieuwegein to see a Dutch water
infrastructure set up years ago.
</p> <p style={{textAlign:'justify'}}>
They were also given a tour around the KWR premises, focusing on the difficulties/problems of a ‘typical’
Dutch water infrastructure. This led to important questions and fruitful discussions. The tour
concluded with a short KWR presentation, sharing information about various water pipe
leakages, detection methods and their repair test results.
                    
                
                  </p>
                 
              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article1
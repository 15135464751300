import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg6 from '../assets/img/smart.jpg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article2 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  Project TUBERS attends Smart Water Utilities 2023                 
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 05/07/2023
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg6} title=""  width="520" height="291" />
               <p style={{textAlign:'justify'}}> The <a id='hyperlink' href="https://www.smart-water-utilities.com/">Smart Water Utilities</a> conference took place in Amsterdam between 28 - 29 June 2023,
uniting experts, researchers, technology companies and providers regarding water leakage and
smart water technologies. Therefore, the TUBERS project could not miss this event, so
representatives from our partner <a id='hyperlink' href="https://www.alsymex-alcen.com/">Alsymex</a> attended it. The goal was to discuss the current
issues of the water distribution systems in Europe while bringing forth the TUBERS holistic
solution that aims to dive deep into the inspection, detection, and repair of water leakages in
water pipeline systems.</p>
<p style={{textAlign:'justify'}}>
During the event, there were discussions with stakeholders who showed interest in the TUBERS
robotic ecosystem. Additionally, the conference hosted speeches from experts in the sector of
sensor technologies and data services like the Fracta software solution of <a id='hyperlink' href="https://www.kurita.eu/en/">Kurita Europe GmbH</a>
that can inspect 100,000 km of pipes based on past leakage events combined with risk analysis
to better plan water network maintenance practices. Another great example is <a id='hyperlink' href="https://www.layermark.com/">Layermark</a>, which
works with the Washington DC water company to identify and label corrosion and cracks inside
the pipeline using AI technologies and sensors.
</p>
<p style={{textAlign:'justify'}}>
Overall, the engaging conference stirred interesting conversations, posing as an excellent
platform for showcasing our future TUBERS prototype.</p>

              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article2
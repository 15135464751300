import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg10 from '../assets/img/tubers11.jpg'
import blogimg11 from '../assets/img/tubers12.jpg'
import pdffile from './pdffile.pdf'
import icon from '../assets/img/pdficon.svg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article8 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  M21 Meeting finds TUBERS in Bordeaux           
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 30/05/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg11} title=""  width="520" height="291" />
               <p style={{textAlign:'justify'}}>Coming closer to the two-year mark, the M21 meeting for our Horizon Europe TUBERS project
was in order. It was hosted by our project partner, <a id='hyperlink' href='https://www.alsymex-alcen.com/'>Alsymex</a> and took place on May 23-24 in
Bordeaux. At the time of the TUBERS assemblage, the city of Bordeaux hosted the Olympic
flame in light of the upcoming Olympic Games in Paris, so celebrations were happening all
around.
</p>
<img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg10} title=""  width="520" height="291" />
<p style={{textAlign:'justify'}}>
The meeting brought together all the project partners (<a id='hyperlink' href='https://www.twi-hellas.com/'>TWI Hellas</a>, 
<a id='hyperlink' href='https://demcon.com/'> DEMCON</a>, <a id='hyperlink' href='https://bendabl.com/'>Bendabl</a>,
<a id='hyperlink' href='https://www.essex.ac.uk/'> University of Essex</a>, <a id='hyperlink' href='https://www.brabantwater.nl/'> Brabant Water</a>, 
<a id='hyperlink' href='https://www.evides.nl/'> Evides</a>, and <a id='hyperlink' href='https://www.vitens.nl/'>Vitens</a>) to discuss progress, challenges, and
next steps. It covered a wide range of topics, including updates on administrative and project
management matters, as well as the latest advancements in technology and research. More
specifically, the technological focus was on work done around the snake-like robot
development, the soft robotic system, the ultrasonic inspection system, the machine learning
algorithms, and the upcoming system integration. Lastly, the partners were guided around
Alsymex’s research facility and were shown some of their kindred projects and technologies.</p>
<p style={{textAlign:'justify'}}>Overall, the gathering was a success and an excellent opportunity for knowledge sharing and
collaboration, again reinforcing the partners positive atmosphere.</p>


              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article8
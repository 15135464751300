import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import blogimg1 from '../assets/img/blogimg1.jpg'
import blogimg3 from '../assets/img/article2.png'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article2 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  The 1st TUBERS project's event participation                  
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 24/10/2022
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg3} title=""  width="520" height="291" />
                <p style={{textAlign:'justify'}}>
                The TUBERS Project has participated in the launch event:
<a id='hyperlink'href="https://eu-robotics.net/paving-the-way-towards-the-next-generation-of-ri-excellence-in-ai-data-and-robotics/"> Paving the way towards the next
generation of R&I excellence in AI, Data, and Robotics. </a>
The event occurred on the 17th of
October, 2022, starting at 9:15 am till 1:10 pm. During the event, the TUBERS' objectives,
technology and general solutions to solve leakage in the water distribution system were
presented.</p>
<p style={{textAlign:'justify'}}>
Through a video presentation, the environmental and societal issues surrounding leakage in the
pipe system, with statistics and examples, were also explored. Furthermore, evidence and
suggestions were showcased on how the TUBERS project will use 24/7 AI inspection and an
ecosystem of new technologies to address water distribution-related problems. Additionally, the
project video provided information about the expected aftermath of TUBERS’ implementation
regarding science, economy, society, and sustainability.
</p>
<p style={{textAlign:'justify'}}>
During the launch event, participants had the opportunity to learn about the TUBERS project
and the objectives we aim to implement to successfully stop water leakages in the future. The
event was in collaboration with many other projects, a part of the <a id='hyperlink'href="https://commission.europa.eu/index_en">European Commission </a> 
and <a id='hyperlink' href="https://research-and-innovation.ec.europa.eu/funding/funding-opportunities/funding-programmes-and-open-calls/horizon-europe_en">Horizon Europe</a>.
TUBERS project was included in the green deal section of the event, along
with ALCHIMIA, CLARUS, DARROW, DIGIFOREST, Grinner, RECLAIM, Romain, and Smart
Droplets. The above work towards a greener future by developing AI and robotics solutions.
</p> <p style={{fontWeight:'600'}}>
Check out the TUBERS project presentation:
</p>
<a id='hyperlink' href="https://youtu.be/iOpAqttJZ_0">https://youtu.be/iOpAqttJZ_0</a>                
              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article2